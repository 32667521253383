/* Navbar container */
.navbar {
  background-color: #ffffff;
  border-radius: 50px;
  margin: 20px auto;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: border-radius 0.3s ease, box-shadow 0.3s ease;
}

/* Remove rounded edges when menu is open */
.navbar.menu-open {
  border-radius: 20px 20px 0 0;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08);
}

/* Centering the navbar content */
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

/* Navbar logo */
.navbar-logo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: auto;
  margin-left: 20px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
}

.navbar-logo:hover {
  color: #004e92;
}

.navbar-logo::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #004e92;
  transition: width 0.3s ease;
}

.navbar-logo:hover::after {
  width: 100%;
}

/* Menu icon (hamburger) */
.menu-icon {
  display: none;
  font-size: 1.8rem;
  color: #333;
  cursor: pointer;
  margin-left: auto;
  transition: transform 0.3s ease, color 0.3s ease;
  padding: 4px;
}

.menu-icon:hover {
  color: #004e92;
}

.navbar.menu-open .menu-icon {
  transform: rotate(180deg);
  color: #004e92;
}

/* Navigation links */
.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 10;
}

/* Navigation items */
.nav-links li {
  margin: 0 10px;
}

/* Navigation links styling */
.nav-item {
  text-decoration: none;
  color: #333;
  padding: 8px 16px;
  border-radius: 25px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

/* Hover effect */
.nav-item:hover {
  background-color: #004e92;
  color: #fff;
  transform: translateY(-2px);
}

.nav-item.active {
  background-color: #013a6b;
  color: #fff;
  box-shadow: 0 4px 8px rgba(1, 58, 107, 0.2);
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .nav-links {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0;      
    box-sizing: border-box; 
    background-color: #ffffff;
    border-radius: 0 0 20px 20px;
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    opacity: 0;
    transform: translateY(-10px);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    z-index: 100;
  }

  .nav-links.active {
    max-height: 300px; 
    padding: 15px 0;
    opacity: 1;
    transform: translateY(0);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  }

  .navbar.menu-open .nav-links {
    border-radius: 0 0 20px 20px;
  }

  .nav-links li {
    margin: 8px 0;
    width: 100%;
    text-align: center;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  
  .nav-links.active li {
    opacity: 1;
    transform: translateY(0);
  }
  
  .nav-links.active li:nth-child(1) { transition-delay: 0.05s; }
  .nav-links.active li:nth-child(2) { transition-delay: 0.1s; }
  .nav-links.active li:nth-child(3) { transition-delay: 0.15s; }
  .nav-links.active li:nth-child(4) { transition-delay: 0.2s; }

  .nav-item {
    display: inline-block;
    padding: 10px 20px;
    width: 80%;
    transition: all 0.3s ease;
  }
  
  .nav-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 78, 146, 0.15);
  }

  .navbar-container {
    padding: 10px 20px;
  }
}

